@import "base";

@import "map/_leaflet";
@import "map/_leaflet.contextmenu";
@import "map/_prunecluster";
@import "map/_leaflet.label";
@import "map/_leaflet.draw";
@import "map/_leaflet.measure";
@import "map/_leaflet.routing.machine";

.leaflet-control-layers-toggle {
	background-image: url('/img/layers.png');
}
.leaflet-retina .leaflet-control-layers-toggle {
	background-image: url('/img/layers-2x.png');
}

// Search panel

.search-control {
	top: -64px;
	left: 36px;
	width: 280px;

	> form {
		display: table;
		> span {
			display: table-cell;
			border-radius: 2px 0 0 2px;
			padding-left: 12px;
			border: 0 none;
			width: 100%;
		}

		> button {
			display: table-cell;
			border-radius: 0 2px 2px 0;
			//vertical-align: middle;
			padding: 2px 18px;
			border: none;
			height: 31px;

			> i {
				vertical-align: middle;
			}
		}
	}
}

.tracking-control {
	position: absolute;
	top: 36px;
	left: 35px;
	width: 280px;
	line-height: 32px;
	display: table;

	span {
		display: block;
		width: 100%;
		cursor:  pointer;
		min-height: 32px;
		
	}

	i {
		vertical-align: middle;
		color: $grey-600;
		padding: 0 6px;
		padding-top: 0.2em;
	}

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		background-color: transparent;
		color: $grey-600;
		border-radius: 0 2px 2px 0;
		padding: 0 6px;

		i {
			vertical-align: middle;
		}
	}
}

.tracking-list {
	display: flex;
	flex-flow: column;
}

.tracking-item {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	p {
		margin: 0;
	}
	&:not(:first-of-type) {
		border-top: 1px solid #757575;
		padding-left: 1em;
	}
}


.leaflet-control {
	@include paper;
}

.leaflet-bar, .leaflet-bar a {
	border-radius: 2px !important;
}

.leaflet-control-mouseposition {
	background-color: #fff;
	padding: 0 5px;
	font-size: 11px;
}

.leaflet-control-minimap {
	/*border:solid rgba(255, 255, 255, 1.0) 3px;*/
	background: #f8f8f9;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	border-radius: 8px;
	@include transition(width .2s, height .2s);
	z-index:1;
	position: absolute;
	bottom:0;
}

.leaflet-control-minimap a {
	background-color: #ffffff;
	display: block;
	outline: none;
	z-index: 1000;
	position: absolute;
	transition: transform .2s;
	-webkit-transition: -webkit-transform .2s;
}

.leaflet-control-minimap a.minimized {
	@include transform(rotate(180deg));
}

.leaflet-control-minimap-toggle-display {
	background-image: url("/img/toggle.png");
	border-radius: 2px 0 2px 0;
	height: 19px;
	width: 19px;
	position: absolute;
	bottom: 0;
	left: 0;
}

.leaflet-container {
	font-family: "Roboto", sans-serif;
}

.leaflet-control-radar a {
	background-image: url('../img/icons/radar.png');
}
.leaflet-control-radar a.active {
	background-image: url('../img/icons/radar_active.png');
}

.leaflet-radar-container {
	width: 320px;
	height: 200px;
	overflow: auto;
	background-color: #ffffff;

	> table {
		width: 100%;
		tr:hover {
			background-color: $hover-color;
		}

		td {
			padding-left: 4px;
			padding-right: 4px;

			&:nth-child(2) {
				text-align: right;
			}
		}
	}
}

/* Esri control */

.leaflet-control-esri {
	box-shadow: 0 1px 5px rgba(0,0,0,0.4);
	background: #fff;
}
.leaflet-control-esri-toggle {
	width: 32px;
	height: 32px;
	background: url(/img/layers.png) no-repeat 50% 50%;
	display: block;
}
.leaflet-retina .leaflet-control-esri-toggle {
	background-image: url(/img/layers-2x.png);
	background-size: 26px 26px;
}
.leaflet-touch .leaflet-control-esri-toggle {
	width: 44px;
	height: 44px;
}
.leaflet-control-esri .leaflet-control-esri-list,
.leaflet-control-esri-expanded .leaflet-control-esri-toggle {
	display: none;
}
.leaflet-control-esri-expanded .leaflet-control-esri-list {
	display: block;
	position: relative;
	max-height: 500px;
	overflow: auto;
}
.leaflet-control-esri-expanded {
	padding: 6px 10px 6px 6px;
	color: #333;
	background: #fff;
}
.leaflet-control-esri-selector {
	margin-top: 2px;
	position: relative;
	top: 1px;
}
.leaflet-control-esri label {
	display: block;
}
.leaflet-control-esri-separator {
	height: 0;
	border-top: 1px solid #ddd;
	margin: 5px -10px 5px -6px;
}


//  Notifier
.leaflet-notifier {
	position: absolute;
	top: 8px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 13pt;
	z-index: 9999;
	padding: 8px;

	span {
		padding: 0 8px;
	}
}

// Routerounds
.leaflet-routerounds{
  height: 100%;
  position: relative;

  .leaflet-control-minimap{
    display: none;
  }

  #ridership-player-container{
    display: block;

    #ridership-slider{
      margin: 10px;
    }
  }
}

.leaflet-control-toggle-leftpanel,
.leaflet-control-alert {
	width: 26px;
	height: 26px;
	cursor: pointer;

	text-align: center;
	vertical-align: middle;

	color: #808080;

	&.show {
		color: #000;
	}
}

/*
.leaflet-control-layers {
	z-index: 8;
}

.leaflet-control-esri {
	z-index: 7;
}

.leaflet-control-layers-expanded {
	position: relative;
	overflow: unset !important;
	max-height: unset !important;
	padding: 0;

	.leaflet-control-layers-toggle {
		display: block;
	}

	>.leaflet-control-layers-list {
		position: absolute;
		max-height: 350px;
		overflow-y: auto;
		top: 0;
		right: 0;
		width: 200px;
		background-color: #ffffff;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		-ms-border-radius: 2px;
		border-radius: 2px;
		padding: 0 7px;

		.leaflet-control-layers-separator {
			margin: 5px 0;
		}
	}
}
*/


.road-sign-marker {
	line-height: 1;

	span {
		font-weight: 600;
		width: 100%;
		
		display: block;
		text-align: center;
	}
}

.semantica-marker {
	width: auto !important;
	height: auto !important;

	line-height: normal;
	background-color: #ffffff;
	border: 1px solid #808080;
	padding: 0 2px;

	div {
		text-align: center;
		min-width: 100px !important;
		width: max-content !important;
		width: -moz-max-content !important;
	}
}