@import "../base", "../colors";

.leaflet-contextmenu {
    display: none;
	margin: 0;
	padding: 8px 0;
    cursor: default;
	@include paper(3);
	@include no-select;

	a.leaflet-contextmenu-item {
		display: block;
		color: $grey-900;
		line-height: 36px;
		font-size: 13px;

		min-width: 200px;

		padding: 0px 20px 0px 50px;
		margin: 0 0 0 4px;

		cursor: pointer;
		outline: none;
		text-decoration: none;

		&:hover, &:focus {
			background-color: $hover-color;
			margin-left: 0;
			border-left: 4px solid $grey-600;
		}

	}

	a.leaflet-contextmenu-item-disabled {
		opacity: 0.5;

		&.over {
			background-color: inherit;
		}
	}
}

.leaflet-contextmenu-icon {
    margin: 11px 8px 0 0;
    width: 16px;
    height: 16px;
    float: left;
    border: 0;

	&.material-icons {
		color: $grey-600;
		width: auto;
		height: auto;
		margin: 6px 6px 0px -37px;
		position: absolute;
	}
}

.leaflet-contextmenu-separator {
    border-bottom: 1px solid $separator-color;
    margin: 6px 0;
}