.leaflet-control-route a {
    background-image: url('../../img/icons/route.png');
}
.leaflet-control-route a.active {
    background-image: url('../../img/icons/route_active.png');
}
.leaflet-routing-container {
    width: 320px;
    background-color: white;
    padding-top: 4px;
    transition: margin-right 0.2s ease;
}

.leaflet-control-container .leaflet-routing-container-hide {
    margin-right: -340px;
}

.leaflet-routing-container h2 {
    font-size: 14px;
	margin: 2px 0 6px 0;
}

.leaflet-routing-container h3 {
    font-size: 12px;
    font-weight: normal;
	margin: 2px 0 6px 0;
}

.leaflet-routing-alt, .leaflet-routing-geocoders {
    padding: 6px;
    margin-top: 2px;
    margin-bottom: 6px;
    border-bottom: 1px solid #ccc;
    max-height: 320px;
    overflow-y: auto;
    transition: all 0.2s ease;
}

.leaflet-bar .leaflet-routing-alt:last-child {
    border-bottom: none;
}

.leaflet-routing-alt-minimized {
    color: #888;
    max-height: 64px;
    overflow: hidden;
    cursor: pointer;
}

.leaflet-routing-alt table {
    border-collapse: collapse;
}

.leaflet-routing-alt tr:hover {
    background-color: #eee;
    cursor: pointer;
}

.leaflet-routing-alt::-webkit-scrollbar {
    width: 8px;
}

.leaflet-routing-alt::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #eee;
}

.leaflet-routing-alt::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #888;
}

.leaflet-routing-icon {
    background-image: url('../../img/leaflet.routing.icons.png');
    -webkit-background-size: 240px 20px;
    background-size: 240px 20px;
    background-repeat: no-repeat;
    margin: 0;
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
}

.leaflet-routing-icon-continue         { background-position: 0 0; }
.leaflet-routing-icon-sharp-right      { background-position: -20px 0; }
.leaflet-routing-icon-turn-right       { background-position: -40px 0; }
.leaflet-routing-icon-bear-right       { background-position: -60px 0; }
.leaflet-routing-icon-u-turn           { background-position: -80px 0; }
.leaflet-routing-icon-sharp-left       { background-position: -100px 0; }
.leaflet-routing-icon-turn-left        { background-position: -120px 0; }
.leaflet-routing-icon-bear-left        { background-position: -140px 0; }
.leaflet-routing-icon-depart           { background-position: -160px 0; }
.leaflet-routing-icon-enter-roundabout { background-position: -180px 0; }
.leaflet-routing-icon-arrive           { background-position: -200px 0; }
.leaflet-routing-icon-via              { background-position: -220px 0; }

.leaflet-routing-geocoders div {
    padding: 4px 0px 4px 0px;
}

.leaflet-routing-geocoders input {
    width: 303px;
    line-height: 1.67;
    border: 1px solid #ccc;
}

.leaflet-routing-geocoders button {
    font: bold 18px 'Lucida Console', Monaco, monospace;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    margin: 0;
    margin-right: 3px;
    float: right;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.leaflet-routing-geocoders button:hover {
    background-color: #eee;
}

.leaflet-routing-geocoders input,.leaflet-routing-remove-waypoint,.leaflet-routing-geocoder {
    position: relative;
}

.leaflet-routing-geocoder-result {
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    position: absolute;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease;
    z-index: 1000; /* Arbitrary, but try to be above "most" things. */
}

.leaflet-routing-geocoder-result table {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 0 0 4px 4px;
    background-color: white;
    cursor: pointer;
}

.leaflet-routing-geocoder-result-open {
    max-height: 800px;
}

.leaflet-routing-geocoder-selected, .leaflet-routing-geocoder-result tr:hover {
    background-color: #eee;
}

.leaflet-routing-geocoder-no-results {
    font-style: italic;
    color: #888;
}

.leaflet-routing-remove-waypoint {
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.leaflet-routing-remove-waypoint:after {
    position: absolute;
    display: block;
    width: 15px;
    height: 1px;
    z-index: 1;
    right: 1px;
    top: 4px;
    bottom: 0;
    margin: auto;
    padding: 2px;
    font-size: 18px;
    font-weight: bold;
    content: "\00d7";
    text-align: center;
    cursor: pointer;
    color: #ccc;
    background: white;
    padding-bottom: 16px;
    margin-top: -16px;
    padding-right: 4px;
    line-height: 1;
}

.leaflet-routing-remove-waypoint:hover {
    color: black;
}

.leaflet-routing-instruction-distance {
    width: 48px;
}
