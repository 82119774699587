.leaflet-control-measure a {
    background-image: url('../../img/icons/ruler.png');
}
.leaflet-control-measure a.active {
    background-image: url('../../img/icons/ruler_active.png');
}
.leaflet-control-area a {
    background-image: url('../../img/icons/area.png');
}
.leaflet-control-area a.active {
    background-image: url('../../img/icons/area_active.png');
}
#tooltip
{
	display: none;
	position: absolute;
	background: #666;
	color: white;
	opacity: 0.5;
	padding: 10px;
	border: 1px dashed #999;
	font-family: sans-serif;
	font-size: 12px;
	height: 40px;
	line-height: 20px;
	z-index: 9999;
	margin-left: 14px;
	margin-top: 14px;
}
.measuring-line-for-look {
    stroke-dasharray:3, 20;
}
.measuring-label-tooltip .leaflet-popup-content-wrapper {
    border-radius: 4px 4px 4px 4px;
    opacity: 0.7;
    padding: 1px;
    text-align: center;
    margin-left: 90px;
    margin-bottom: -60px;
    margin-top: -17px;
}
.measuring-label-tooltip .leaflet-popup-content {
    margin: 0 5px;
    /*width: 0;*/
}
.measuring-label-tooltip .leaflet-popup-tip-container {
    display: none;
}
