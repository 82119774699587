@import "../base";

.leaflet-label {
	background: #ffffff;
	background-clip: padding-box;
	border-radius: 4px;
	border: 0 none;
	@include shadow(2);
    //box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);
	color: #000000;
	display: block;
	//font-weight: 500;
	padding: 3px 8px;
	position: absolute;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	pointer-events: none;
	white-space: nowrap;
	//z-index: 9999;
}

.leaflet-label.leaflet-clickable {
	cursor: pointer;
	pointer-events: auto;
}

.leaflet-label:before,
.leaflet-label:after {
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	content: none;
	position: absolute;
	top: 7px;
}

.leaflet-label:before {
	border-right: 6px solid #ffffff;
	left: -6px;
}

.leaflet-label:after {
	border-left: 6px solid #ffffff;
	right: -6px;
}

.leaflet-label-right:before,
.leaflet-label-left:after {
	content: "";
}
