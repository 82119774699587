@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
@mixin no-wrap {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
@mixin box-shadow($shadows...) {
    -moz-box-shadow: $shadows;
    -webkit-box-shadow: $shadows;
    box-shadow: $shadows;
}
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}
@mixin transition($transition...) {
	-moz-transition:    $transition;
	-o-transition:      $transition;
	-webkit-transition: $transition;
	transition:         $transition;
}
@mixin transform($transform...) {
	-moz-transform:    $transform;
	-ms-transform:     $transform;
	-o-transform:      $transform;
	-webkit-transform: $transform;
	transform:         $transform;
}

@mixin shadow($level: 1){
	@if $level == 0 {
		@include box-shadow(none);
	} @else if $level == 1 {
		@include box-shadow(0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24));
	} @else if $level == 2 {
		@include box-shadow(0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23));
	} @else if $level == 3 {
		@include box-shadow(0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23));
	} @else if $level == 4 {
		@include box-shadow(0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22));
	} @else if $level == 5 {
		@include box-shadow(0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22));
	}
}
@mixin shadow-bottom($level: 1){
	@if $level == 1 {
		@include box-shadow(0 4px 3px -3px rgba(0,0,0,0.12), 0 3px 2px -2px rgba(0,0,0,0.24));
	} @else if $level == 2 {
		@include box-shadow(0 9px 6px -6px rgba(0,0,0,0.16), 0 9px 6px -6px rgba(0,0,0,0.23));
	} @else if $level == 3 {
		@include box-shadow(0 30px 20px -20px rgba(0,0,0,0.19), 0 12px 6px -6px rgba(0,0,0,0.23));
	} @else if $level == 4 {
		@include box-shadow(0 42px 28px -28px rgba(0,0,0,0.25), 0 20px 10px -10px rgba(0,0,0,0.22));
	} @else if $level == 5 {
		@include box-shadow(0 57px 38px -38px rgba(0,0,0,0.30), 0 27px 12px -12px rgba(0,0,0,0.22));
	}
}
@mixin paper($level: 1) {
	border: 0 none;
	background-color: #ffffff;
    @include border-radius(2px);
	@include shadow($level);
}

$overlay_z: 1054; // 1100 JQuery overlay is [1050 .. ?]

$window-width: calc(100% - 0px);
